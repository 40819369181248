<template> 
  <div class='px-2 lg:px-0 mt-4 lg:mt-12'>
    <div v-if='sessionsOnDate.length > 0'>
      <div class='text-sm mb-2 text-gray-500'>Please select a session to ask a question.</div>
      <div class='flex flex-row justify-start gap-x-8'>
        <div>
          <h1 class='font-semibold mb-2'>Session Date</h1>
          <select
            class='w-full lg:w-40 p-2 border border-gray-200 rounded-md cursor-pointer mb-2' 
            v-model='selectedDate'>
            <option v-for='date in selectableDates' :key='`date-${date}`' :value='date'>{{date}}</option>
          </select>
        </div>
        <div>
          <h1 class='font-semibold mb-2'>Rooms</h1>
          <select
            class='w-full lg:w-40 p-2 border border-gray-200 rounded-md cursor-pointer mb-2' 
            v-model='selectedRoom'>
            <option v-for='room in selectableRooms' :key='`room-${room}`' :value='room'>{{room}}</option>
          </select>
        </div>
      </div>
      <div>
        <h1 class='font-semibold mb-2'>Sessions</h1>
        <select
          v-if='selectedRoom'
          v-model='selectedSessionId'
          class='w-full p-3 border border-gray-200 rounded-md cursor-pointer mb-2'>
          <option v-for='session in filteredSessions'
                  :key='`session-option-${session.id}`'
                  :value='session.id'>
            <div class='truncate'>
              {{session.title}}
            </div>
          </option>
        </select>
        <div v-else class='text-sm text-gray-500'>Select a room to see available sessions.</div>
      </div>
      <session-question v-if='selectedSessionId' class='qna-session-question' />
      <div v-else>
        <div class='bg-gray-100 p-8 text-sm text-gray-600 rounded-md'>Please select the session you would like to leave a question.</div>
      </div>
    </div>
    <div v-else>
      <div class='bg-gray-100 p-8 text-sm text-gray-600 rounded-md'>There are no sessions for today</div>
    </div>
  </div>
</template>

<script>
import SessionQuestion from '@/components/sessions/SessionQuestion.vue'
import { mapState, mapActions }    from 'vuex'
import dayjs from 'dayjs'
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'Qna',
  data () {
    return {
      selectedDate: '',
      selectedRoom: '',
      selectedSessionId: null
    }
  },
  components: {
    SessionQuestion
  },
  watch: {
    sessions: {
      handler: function (newVal) {
        if (newVal && newVal.length > 0) {
          if (this.$route.query.session_id) {
            let sessionsByDateindex = newVal.findIndex(sessionsByDate => sessionsByDate.sessions.findIndex(session => session.id === parseInt(this.$route.query.session_id)) > -1 )
            if (sessionsByDateindex > -1) {
              let whichDate = newVal[sessionsByDateindex].date
              let whichRoom = newVal[sessionsByDateindex].sessions.find(session => session.id === parseInt(this.$route.query.session_id)).locations[0].split('||')[0]
              this.selectedDate = whichDate
              this.selectedRoom = whichRoom
              this.selectedSessionId = parseInt(this.$route.query.session_id)
            } else {
              let query = Object.assign({}, this.$route.query)
              delete query.session_id
              this.$router.replace({ query }).catch(() => {})
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    filteredSessions: {
      handler: function (newVal) {
        if (newVal && newVal.length > 0) {
          let index = newVal.findIndex(session => session.id === this.selectedSessionId)
          if (index > -1) {
            // do nothing
          } else {
            let query = Object.assign({}, this.$route.query)
            delete query.session_id
            this.$router.replace({ query }).catch(() => {})
            this.selectedSessionId = null
          }
        }
      },
      deep: true
    },
    selectedSessionId: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let query = Object.assign({}, this.$route.query)
          query.session_id = newVal
          this.$router.replace({ query }).catch(() => {})
        }
      },
      immediate: true
    },
    selectableDates :{
      handler: function (newVal) {
        if (newVal && newVal.length !== 0 && !this.selectedDate) {
          if (newVal.includes(dayjs().format('YYYY-MM-DD'))) {
            this.selectedDate = dayjs().format('YYYY-MM-DD')
          } else {
            this.selectedDate = newVal[0]
          }
        }
      },
      immediate: true
    },
    selectableRooms :{
      handler: function (newVal) {
        if (newVal.length !== 0 && !this.selectedRoom) {
          this.selectedRoom = newVal[0]
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('sessions', [
      'sessions'
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    selectableDates () {
      return uniqBy(this.sessions.map(session => session.date)).sort()
    },
    selectableRooms () {
      return uniqBy(this.sessionsOnDate.map(session => session.locations.map(location => location.split('||')[0])).flat()).sort()
    },
    sessionsOnDate () {
      let sessions = this.sessions.find(obj => obj.date === this.selectedDate)
      return sessions ? sessions.sessions : []
    },
    filteredSessions () {
      return this.sessionsOnDate.filter(session => session.locations[0].split('||')[0] === this.selectedRoom)
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getSessions',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'Qna'} })
      }
    })
  },
  mounted () {
    this.getSessions('qna')
  }
}
</script>

<style type='scss' scoped>
.qna-session-question {
  height: 20rem;
}

@media (min-width: 1024px) { 
  .qna-session-question {
    height: calc(100vh - 34rem);
  }
}
</style>
